<template>
  <div>
    <div class="bg-blue-600 ml-6 mr-1 mt-1 p-2">
      <div class="text-xs font-bold text-white">Trade In</div>
      <div class="text-xl font-bold text-white"> - €</div>
    </div>
    <div class="flex flex-row ml-6">
      <div class="bg-blue-100 mr-1 mt-1 p-2 w-1/2 border border-blue-100">
        <div class="text-xs font-bold text-blue-600">Market</div>
        <div class="text-lg font-bold text-blue-700">- €</div>
      </div>
      <div class="border-2 border-blue-200 mr-1 mt-1 p-2 w-1/2">
        <div class="text-xs font-bold text-blue-600">Market days</div>
        <div class="text-lg font-bold text-blue-700">-</div>
      </div>
    </div>
  </div></template>

<script>
    export default {
      name: 'BenchmarkHeader',
      props: ['car']
    };
</script>
