<template>
  <div class="flex flex-row w-full sticky top-0 z-10 bg-white">
    <div class="">
      <div class="w-48 h-36 p-1" v-if="getPicture()">
        <img class="w-full" alt="Main image" :src="getPicture()">
      </div>
      <div class="w-48 h-36 p-1 bg-white" v-else>
        <div class="bg-gray-100 w-full h-full flex flex-row justify-center items-center">
          <span class="text-4xl"><i class="fal fa-camera-alt"></i></span>
        </div>
      </div>
    </div>
    <div class="flex flex-col mx-2">
      <div class="flex flex-row">
        <div class="ml-2 mt-2">
          <div class="flex justify-start">
            <label class="block text-sm font-medium leading-5 text-gray-700">VIN Number</label>
            <span class="text-sm ml-2 leading-5 text-gray-300 font-light hover:text-blue-500 cursor-pointer"><i class="fas fa-info-circle"></i></span>
          </div>
          <div class="mt-1 relative rounded-sm shadow-sm w-76 flex flex-row">
            <input type="text"  v-model="lead().condition.car.vin" @click="select($event)" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 border-l-4 border-gray-300 rounded" placeholder="Vin number">
            <div class="absolute inset-y-0 right-0 pr-16 flex items-center pointer-events-none text-gray-400 text-xs">
              <i class="far fa-asterisk"></i>
            </div>
            <button class="-ml-1 relative inline-flex items-center px-2 pr-3 py-2 border border-gray-300 text-sm leading-4 font-medium text-gray-400 bg-gray-50 hover:text-gray-400 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" v-tooltip.top-center="'This is important'">
              <i class="fal fa-bolt"></i>
            </button>
            <button class="-ml-1 relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-r-md text-gray-400 bg-gray-50 hover:text-gray-400 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150">
              <i class="fal fa-file"></i>
            </button>
          </div>
        </div>
        <div class="ml-4 mt-2">
          <div class="flex justify-between">
            <label class="block text-sm font-medium leading-5 text-gray-700">1° Registration</label>
          </div>
          <div class="mt-1 relative w-36">
            <!--            <a-date-picker id="first_registration" :defaultValue="moment('1900-01-01', 'YYYY-MM-DD')" :value="moment(lead().condition.car.firstRegistrationDate)" format="DD/MM/YYYY" @change="setFirstRegistration" class="w-full border border-l-4 border-gray-300 rounded shadow" ></a-date-picker>-->
            <t-datepicker userFormat="d/m/Y" v-model="lead().condition.car.firstRegistrationDate" initial-view="year" />
          </div>
        </div>
        <div class="ml-6 mt-2">
          <div class="flex justify-center">
            <label class="block text-sm font-medium leading-5 text-gray-700">Margin</label>
          </div>
          <div class="mt-2 relative">
            <toggle-checkbox v-model="lead().condition.car.margin"></toggle-checkbox>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="ml-2 mt-2">
          <div class="flex justify-start">
            <label for="registration" class="block text-sm font-medium leading-5 text-gray-700">Registration</label>
          </div>
          <div class="mt-1 relative rounded-sm shadow-sm w-36 flex flex-row">
            <input id="registration" v-model="lead().condition.car.registration" type="text" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="License plate">
            <button class="-ml-1 relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-r-md text-gray-400 bg-gray-50 hover:text-gray-400 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-300 transition ease-in-out duration-150">
              <i class="fal fa-bolt"></i>
            </button>
          </div>
        </div>
        <div class="ml-4 mt-2">
          <div class="flex justify-start">
            <label for="reference" class="block text-sm font-medium leading-5 text-gray-700">Reference</label>
            <span class="text-sm ml-2 leading-5 text-gray-300 font-light" ><i class="fas fa-info-circle"></i></span>
          </div>
          <div class="mt-1 relative rounded-sm shadow-sm w-36">
            <input id="reference" v-model="lead().condition.car.reference" type="text" class="placeholder-gray-100::placeholder block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded" placeholder="Reference">
          </div>
        </div>
        <div class="ml-4 mt-2">
          <div class="flex justify-start">
            <label for="kilometers" class="block text-sm font-medium leading-5 text-gray-700">Kilometers</label>
            <span class="text-sm ml-2 leading-5 text-gray-300 font-light" ><i class="fas fa-info-circle"></i></span>
          </div>
          <div class="mt-1 relative rounded-sm shadow-sm w-36">
            <input id="kilometers" type="number" v-model="lead().condition.car.kilometers" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600  border-l-4 border-gray-300 text-right pr-8 border-gray-300 rounded" placeholder="Km.">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400 text-xs">
              <i class="far fa-asterisk"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full">
      <benchmark-header :car="lead().condition.car"></benchmark-header>
    </div>
  </div>
</template>

<script>
import ToggleCheckbox from '@/components/toggle-checkbox';
import BenchmarkHeader from '@/benchmark/views/result/Header';

export default {
  name: "Header",
  props: ['menu'],
  components : {
    'toggle-checkbox': ToggleCheckbox,
    'benchmark-header': BenchmarkHeader
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    lead() {
      return this.$store.getters['stock/lead'];
    },
    setFirstRegistration(date, dateString) {
      console.log(date, dateString);
      this.lead().condition.car.firstRegistrationDate = date.toDate();
      //this.$store.commit('tradein/setCar', car);
    },
    select: function(event) {
      //console.log(event);
      event.target.select();
    },
    getPicture: function() {
      const pics = this.lead().condition.pictures.filter(p => p.item === 'front-left')
      if(pics.length > 0) return pics[0].thumbnailUrl;
      return null;
    },
  },
}
</script>

<style scoped>

</style>